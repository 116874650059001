<template>
	<div>
		<div class="read_box">
			<div class="read_box1">
				<div class="read_mainbox">
					<div class="pdfread_top">
						<div class="read_exitbtn" style="width: auto;">
							<a href="javascript:void(0)" class="fl_l" @click="toExitRead()">退出阅读</a>
						</div>
						<div class="epubread_readbox_top fl_r m_t0">
							<a class="readtype_selected" href="javascript:void(0)" style="cursor: default;">PDF阅读</a>
						</div>
					</div>
					<div class="pdfread_content">
						<div class="pdfread_readcontent">
							<div v-for="(value,index) in PdfItems" :key="index">
								<img :src="value" />
							</div>
						</div>
								
						<div class="read_process pdfread_process">
							<div style="position: relative; left: 50%; margin-left: -300px;" id="slider-bar" class="turnjs-slider">
								<div id="slider"></div>
							</div>
						</div>
					</div>
					<div class="read_menubtn">
						<div class="read_pagechange">
							<a href="javascript:void(0)" @click="previous()" class="read_pre"></a>
							<a href="javascript:void(0)" @click="next()" class="read_next"></a>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="login_footer" style="float: left;">
			<p>ICP备案信息 <a href="https://beian.miit.gov.cn/" target="_blank">陇ICP备2024012976号-2</a></p>
			<p>© 版权所有 甘肃教育出版社有限责任公司 保留所有权利</p>
		</div>
	</div>
</template>

<script>
	/* eslint-disable */
	import '../../util/js/jquery-ui.js'
	import '../../util/js/turn.min.js'
	import '../../util/js/basic.js'
	import '../../theme/jquery.ui.css'
	export default{
		name:'AnliteratureRead',
		data(){
			return{
				PdfPath: '',
				PdfIndex: 1,
				PdfSize: 0,
				PdfItems: [],
			}
		},
		methods:{
			GetPDFDetail() { //获取pdf
				var that = this;
				for (var i = 0; i < that.PdfSize; i++) {
					that.PdfItems.push(that.$ImgUrl + that.PdfPath + '/' + (i - (-1)) + '.jpg');
				}
				setTimeout(function() {
					$("#slider").slider({
						value: that.PdfIndex,
						min: 1,
						max: that.PdfSize,
						slide: function(event, ui) {
							$(".pdfread_readcontent").turn("page", ui.value)
							that.PdfIndex = ui.value;
						}
					});
					setTimeout(function() {
						$(".pdfread_readcontent").turn({
							width: 1100,
							height: 708,
							elevation: 50,
							gradients: true,
							autoCenter: true,
							when: {
								turned: function(e, page) {
									$("#slider").slider("value", page);
									that.PdfIndex = page;
								}
							}
						});
					}, 100)
				}, 100)
			},
			previous() {
				$('.pdfread_readcontent').turn('previous');
			},
			next() {
				$('.pdfread_readcontent').turn('next');
			},
			toExitRead(){ //退出阅读
				window.close()
			}
		},
		mounted(){
			this.PdfPath = this.$route.query.path
			this.PdfSize = this.$route.query.pageCount
			this.GetPDFDetail()
		}
	}
</script>

<style>
	
</style>
